import React from "react";

import genericUser from "../images/genericUser.png";
import bioStyles from "./bioTemplate.module.css";
import GatsbyLink from "gatsby-link";
import webLink from "../images/webLink.svg";
import Head from "../components/head";
import { graphql } from "gatsby";

export const query = graphql`
  query($name: String!) {
    contentfulBio(name: { eq: $name }) {
      name
      websiteLink
      titleOrInstrument
      bioImage {
        file {
          url
        }
      }
      biography {
        biography
      }
    }
    allContentfulBioVideos(filter: { name: { eq: $name } }) {
      edges {
        node {
          videoTitle
          youtubeLink
        }
      }
    }
  }
`;

class bioPage extends React.Component {
  componentDidMount() {
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      let link = v.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + "</a>";
      });
      el.innerHTML = link;
    });
  }
  render() {
    return (
      <>
        <Head title={this.props.data.contentfulBio.name}></Head>
        <section className={bioStyles.bioSection}>
          <div>
            <div className={bioStyles.bioSectionImgContainer}>
              {this.props.data.contentfulBio.bioImage === null ? (
                <>
                  <img src={genericUser}></img>
                  {this.props.data.contentfulBio.websiteLink !== "null" ? (
                    <>
                      <p>Website</p>
                      <a
                        href={this.props.data.contentfulBio.websiteLink}
                        target="_blank"
                      >
                        <img src={webLink}></img>
                      </a>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <img
                    src={this.props.data.contentfulBio.bioImage.file.url}
                  ></img>
                  {this.props.data.contentfulBio.websiteLink !== "null" ? (
                    <>
                      <p>Website</p>
                      <a
                        href={this.props.data.contentfulBio.websiteLink}
                        target="_blank"
                      >
                        <img src={webLink}></img>
                      </a>
                    </>
                  ) : null}
                </>
              )}
            </div>
            <div id={bioStyles.bioRightContainer}>
              <div>
                <h1>{this.props.data.contentfulBio.name}</h1>
                <h3>{this.props.data.contentfulBio.titleOrInstrument} </h3>
                <p>
                  {this.props.data.contentfulBio.biography !== null
                    ? this.props.data.contentfulBio.biography.biography
                    : null}
                </p>
              </div>
            </div>
          </div>
        </section>

        {this.props.data.allContentfulBioVideos.edges.length > 0 ? (
          <section id={bioStyles.videoSection}>
            <div>
              <h2>Videos</h2>
              {this.props.data.allContentfulBioVideos.edges.map(
                (edge, index) => {
                  let youtubeLink = edge.node.youtubeLink.split("v=")[1];
                  return (
                    <div key={index}>
                      <p>{edge.node.videoTitle}</p>
                      <iframe
                        className={bioStyles.videoAttr}
                        src={`https://www.youtube.com/embed/${youtubeLink}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  );
                }
              )}
            </div>
          </section>
        ) : null}
      </>
    );
  }
}
export default bioPage;
